<template>
  <div class="intro-container">
    <h2>made this...</h2>
    <!-- <svg class="arrow" viewBox="0 0 60 294" xmlns="http://www.w3.org/2000/svg">
      <g stroke="red" stroke-width="6" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <path d="M34.742 3.219c-16.5 38.937-42.12 101.462-11.816 141.277 11.47 15.07 39.594-30.3 18.836-35.809-26.992-7.162-32.379 47.884-29.746 62.829 3.095 17.57 10.156 34.34 12.632 52.007 3.052 21.767 6.09 43.956 3.887 65.825-.52 5.153-6.573-8.05-9.262-12.477-5.546-9.134-10.26-18.747-15.39-28.121M27.512 290.402c15.113-9.902 23.05-21.022 29.226-38.867"/>
      </g>
    </svg> -->
    <svg class="arrow" viewBox="0 0 68 157" xmlns="http://www.w3.org/2000/svg">
      <g stroke="red" stroke-width="7" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <path d="M25.008 4.242c6.634 151.572 30.22 185.828-20.934 102.012M34.867 152.734c20.236-33.589 10.356-15.758 29.313-53.672"/>
      </g>
    </svg>
    <ul id="intro-gal" class="intro-gallery">
      <li
        v-for="person in introWork"
        :key="person.name"
        class="intro-gallery--item"
        :class="{ bigger: person.hover }"
        @mouseenter="hoverName(person)"
        @mouseleave="person.hover = false"
      >
        <p class="job" @mouseenter="person.hover = true">{{ person.job }}</p>
        <h2
          class="name"
          @click="showMoreOnClick(person)"
          @mouseenter="person.hover = true"
        >
          {{ person.name }}
        </h2>
        <!--@mouseover="hoverName(person)" @mouseleave="person.hover=false"-->

        <div
          v-show="person.hover"
          class="animate__animated"
          :class="person.animate"
          @click="showMoreOnClick(person)"
        >
          <v-lazy-image
            :src="person.thumb"
            alt=""
            class="hover-thumb"
            :class="{ 'hide-thumb': person.showMore }"
            :style="{ top: person.top, left: person.left }"
          />
        </div>

        <transition
          @before-enter="beforeOpen"
          @enter="enterProject"
          @leave="leaveProject"
          :css="false"
        >
          <div class="is-selected" v-if="person.showMore">
            <VueSlickCarousel v-bind="settings">
              <div class="slide" v-for="img in person.extraImg" :key="img.id">
                <v-lazy-image :src="img" alt="" />
              </div>
            </VueSlickCarousel>
            <p class="story">{{ person.story }}</p>
          </div>
        </transition>
      </li>
    </ul>

    <div class="cta">
      <router-link to="work">
        <h2>see more work</h2>
      </router-link>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import work from "./data.js";

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      },

      work: work.data,
      introWork: []
    };
  },
  methods: {
    isTouchDevice: function() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    selectRandomPerson: function() { //GET DIFFERENT PERSON ON THE INDEX.HTML EACH TIME
      const randomPerson = Math.floor(Math.random() * this.work.length);
      this.introWork.push(this.work[randomPerson]);
      console.log(this.introWork[0].name);
    },
    hoverName: function(person) {
      person.hover = !person.hover;
    },
    showMoreOnClick: function(person) {
      if (this.isTouchDevice()) {
        person.hover = false; 
        person.showMore = !person.showMore;
      } else if (!this.isTouchDevice()) {
        person.showMore = !person.showMore;
      }
    },
    //OPEN EN CLOSE ANIMATION OF THE WORK AND INTRO GALLERY
    beforeOpen: function(el) { 
      el.style.transform = "scaleY(0)";
      el.style.transformOrigin = "top";
      el.style.transform = "translateY(-50)";
    },
    enterProject: function(el, done) { 
      gsap.to(el, { duration: 0.3, y: 0, scaleY: 1, onComplete: done });
    },
    leaveProject: function(el, done) {
      gsap.to(el, { duration: 0.3, scaleY: 0, onComplete: done });
    },
    pushAnimation: function() { //RANDOM IMAGE HOVER
      for (let i = 0; i < this.work.length; i++) {
        this.work[i].animate = this.calculateAnimation();
        this.work[i].top = `-${this.calculateTop()}px`;
        this.work[i].left = `${this.calculateLeft()}px`;
      }
    },
    calculateAnimation: function() {  //IMAGE HOVER
      const animation1 = "animate__bounceInLeft";
      const animation2 = "animate__fadeInUp";
      const animation3 = "animate__jello";
      const animation4 = "animate__bounceInRight";
      const x = Math.floor(Math.random() * 4);

      switch (x) {
        case 0:
          return animation1;
        case 1:
          return animation2;
        case 2:
          return animation3;
        case 3:
          return animation4;
        default:
          console.log("hahhahaah");
      }
    },
    calculateTop: function() { //IMAGE HOVER
      const top = Math.floor(Math.random() * 200);
      return top;
    },
    calculateLeft: function() { //IMAGE HOVER
      const left = Math.floor(Math.random() * 100);
      return left;
    }
  },
  mounted() {
    this.pushAnimation();
    this.selectRandomPerson();
  }
  // showWork: function(){
  // //    this.$root.$emit('hoi vanuit introGal');
  //     this.$emit('work');
  // }
};
</script>

<style lang="scss" scoped>
@import "../global-scss/variables.scss";

// @mixin shadow-text($x, $y) {
//   color: #ff005d;
//   text-shadow: $x $y #ff005d;
// }

h2 {
  color: $text-color;
}

.intro-container {
  flex: 1;
  @media (min-width: $breakpoint-medium) {
    margin-bottom: 60vh;
  }
}

.arrow{
  height: 4rem;
}

.intro-gallery {
  background-color: transparent;
  list-style: none;
  padding: 0;
  height: 100%;
  max-width: 1200px;
  &--item {
    a {
      text-decoration: none;
      &:hover {
        color: red;
      }
      p {
        font-size: 2rem;
        font-weight: lighter;
        z-index: 0;
        // @include shadow-text(3px, 3px);
      }
    }
  }
}

.name {
  cursor: pointer;
  font-size: 15vw;
  @media(min-width: $breakpoint-medium){
    font-size: 10vw;
  }
  @media(min-width: $breakpoint-large){
    font-size: 6rem;
  }
}

.hover-img {
  position: absolute;
  width: 30vw;
  z-index: 2;
  @media (min-width: 930px) {
    width: 20vw;
  }
  @media (min-width: $breakpoint-xl) {
    width: 10vw;
  }
}

.cta {
  margin-top: 2rem;
  h2 {
    display: inline;
  }
}

.firstPos {
  top: 10px;
  left: 40px;
}

.secondPos {
  top: 50px;
  left: 50px;
}

.intro-gallery--item:nth-child(2) {
  .hover-img {
    width: 170px; //middle hover thumbnail is smaller
  }
}

.intro-gallery--item:nth-child(3) {
  .hover-img {
    width: 100px; //last hover thumbnail is smaller
  }
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.slick-slider {
  width: 100vw;
  margin-bottom: 3rem;
  @media (min-width: $breakpoint-large) {
    width: auto;
  }
}

.slide {
  width: 80% !important;
  max-width: 800px !important;
  margin: 0 auto;
  img {
    width: 100%;
    margin: 0 auto;
    @media (min-width: $breakpoint-medium) {
      width: 50%;
    }
    @media (min-width: $breakpoint-large) {
      width: 60%;
    }
  }
}

.slick-prev {
  left: 1rem;
  transform: scale(2);
  z-index: 999;
  &::before {
    color: $secondary-color;
  }
  @media (min-width: $breakpoint-large) {
    left: 10rem;
  }
}

.slick-next {
  transform: scale(2);
  right: 1rem;
  &::before {
    color: $secondary-color;
  }
  @media (min-width: $breakpoint-large) {
    right: 10rem;
  }
}
</style>
