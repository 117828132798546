const work = { 
    data:[
        {
            name: 'Dit ben ik',
            job: 'cool project!',
            story: '\'Dit ben ik\' is a weekly column i make for the Volkskrant. It consists of a picture of a youngster in his or her own room and it\'s accompanied by a small interview.',
            thumb: require('../assets/thumbs/ditbenik-thumb.jpg'),
            extraImg: [require('../assets/work/ditbenik1.jpg'),require('../assets/work/ditbenik2.jpg'),require('../assets/work/ditbenik3.jpg'),require('../assets/work/ditbenik4.jpg'),require('../assets/work/ditbenik5.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Ronnie Flex',
            job: 'rapper',
            story: 'Mr. flex at home in Rotterdam. As you can see there’s a freshly made plate of pasta on the table. After the pictures he offered me some. Good sauce!',
            thumb: require('../assets/thumbs/ronnie-flex-thumb.jpg'),
            extraImg: [require('../assets/work/ronnie-flex1.jpg'),require('../assets/work/ronnie-flex2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Claudia Cardinale',
            job: 'moviestar',
            story: 'Mrs. Cardinale is an true diva. In the best sense of the word.',
            thumb: require('../assets/thumbs/claudia-cardinale-thumb.jpg'),
            extraImg: [require('../assets/work/claudia-cardinale1.jpg'),require('../assets/work/claudia-cardinale2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Theo Heuft',
            job: 'in these pictures...',
            story: "Here you see Theo Heuft. Theo was the owner of a an establishment called Yab Yum. A well known brothel in Amsterdam. Theo has a thousand stories from that time. He told them all when i visited him in France for the Volkskrant. His wife started a Bed&Breakfast there. He's bored out of his mind.",
            thumb: require('../assets/thumbs/theo-heuft-thumb.jpg'),
            extraImg: [require('../assets/work/theo-heuft1.jpg'),require('../assets/work/theo-heuft2.jpg'),require('../assets/work/theo-heuft3.jpg'),require('../assets/work/theo-heuft4.jpg')],
            hover: false,
            showMore: false,
        },
        {
            name: 'Maarten Spruyt',
            job: 'stylist',
            story: 'Maarten\'s favorite color appears to green. Both his house and his wardrobe was full of it. Quite remarkable. Besides that he’s also a really nice and talented guy.',
            thumb: require('../assets/thumbs/maartenSpruyt-thumb.jpg'),
            extraImg: [require('../assets/work/maarten-spruyt1.jpg'),require('../assets/work/maarten-spruyt2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Fien Troch',
            job: 'Director',
            story: 'Fien in her hotel room during the Rotterdam film festival.',
            thumb: require('../assets/thumbs/fien-troch-thumb.jpg'),
            extraImg: [require('../assets/work/fien-troch1.jpg'),require('../assets/work/fien-troch2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'John de Wolf',
            job: 'Soccer legend',
            story: 'John outside the cafeteria of soccer club Sparta.',
            thumb: require('../assets/thumbs/john-de-wolf-thumb.jpg'),
            extraImg: [require('../assets/work/john-de-wolf1.jpg'),require('../assets/work/john-de-wolf2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Lubomyr Melnyk',
            job: 'Musical mastermind',
            story: 'Lubomyr Melnyk in Brussels before his concert. He invited me to come and listen. Wikipedia says the following regarding mr. Melnyk\'s music: Melnyk is noted for "continuous music", a piano technique based on extremely rapid notes and complex note-series that create a "tapestry of sound". I\'m not sure if i got the whole tapestry of sound thing but it was beautiful nonetheless.',
            thumb: require('../assets/thumbs/melnik-thumb.jpg'),
            extraImg: [require('../assets/work/lubomyr-melnyk1.jpg'),require('../assets/work/lubomyr-melnyk2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Omar',
            job: 'rapper',
            story: 'Omar is a guy from Amsterdam, a rapper and a writer. I wanted to meet him at his home to make some pictures but he wanted to get cigarettes instead. And so it happened.',
            thumb: require('../assets/thumbs/omar-thumb.jpg'),
            extraImg: [require('../assets/work/omar1.jpg'),require('../assets/work/omar2.jpg'),require('../assets/work/omar3.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Child of Lov',
            job: 'musician',
            story: 'The Child of Lov was a good musician and a really nice guy. I made these pictures in Amsterdam.',
            thumb: require('../assets/thumbs/child-of-love-thumb.jpg'),
            extraImg: [require('../assets/work/child-of-love1.jpg'),require('../assets/work/child-of-love2.jpg'),require('../assets/work/child-of-love3.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Marc Bijl',
            job: 'Artist',
            story: 'These pictures were made while he was preparing his show in the \'Groninger museum\'.',
            thumb: require('../assets/thumbs/marc-bijl-thumb.jpg'),
            extraImg: [require('../assets/work/marc-bijl1.jpg'),require('../assets/work/marc-bijl2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Courses',
            job: 'cool project!',
            story: 'I started to really get into courses while making these pictures. Here you\'ll find courses ranging from pole dancing to finding water with a stick. It\'s never too late to learn!',
            thumb: require('../assets/thumbs/courses-thumb.jpg'),
            extraImg: [require('../assets/work/courses.jpg'),require('../assets/work/courses1.jpg'),require('../assets/work/courses2.jpg'),require('../assets/work/courses3.jpg'),require('../assets/work/courses5.jpg'),require('../assets/work/courses6.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Erik Arbores',
            job: 'smart guy',
            story: 'Erik is what people might call a whizzkid. He\'s really smart and also a promising DJ. He\'s got a lot going for him. In 10 years we\'ll see this guy on a mainstage waving his hands in front of huge crowd. Or he\'s waving his nobel prize. Who knows.',
            thumb: require('../assets/thumbs/erik-arbores-thumb.jpg'),
            extraImg: [require('../assets/work/erik-arbores1.jpg'),require('../assets/work/erik-arbores2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Jean Paul Gaultier',
            job: 'fashion baby!',
            story: 'Did I knew Jean Paul Gaultier from something else then the perfume bottle in the shape of a male torso my girlfriend once gave me? I have to admit no. Was i totally nervous meeting him? Yes. What joke did i tell him to make him laugh? You\'ll never know!',
            thumb: require('../assets/thumbs/gaultier-thumb.jpg'),
            extraImg: [require('../assets/work/gaultier1.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Gili',
            job: 'magical',
            story: 'Gili is a Comedian-Mentalist and therefore not averse to the occasional magical photography joke.',
            thumb: require('../assets/thumbs/gili-thumb.jpg'),
            extraImg: [require('../assets/work/gili1.jpg'),require('../assets/work/gili2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'Ronald Giphart',
            job: 'writer',
            story: 'Writer Ronald Giphart on the 2nd of January. We had a brief conversation regarding the use of XTC. He called me a pussy for never trying it. He also said Metallica was old people music.',
            thumb: require('../assets/thumbs/giph-thumb.jpg'),
            extraImg: [require('../assets/work/giph1.jpg'),require('../assets/work/giph2.jpg')],
            hover: false,
            showMore: false
        },
        {
            name: 'The strongest man',
            job: 'cool project!',
            story: 'Together with brother and Volkskrant journalist Jarl van der Ploeg, i dove into the world of pushing stone,lifting steel and pulling trucks. These are all the former champions of the infamous \'Strongest man of Holland\' competition. Also starring the current champion.',
            thumb: require('../assets/thumbs/strongmen-thumb.jpg'),
            extraImg: [require('../assets/work/strongmen1.jpg'),require('../assets/work/strongmen3.jpg'),require('../assets/work/strongmen6.jpg'),require('../assets/work/strongmen7.jpg'),require('../assets/work/strongmen8.jpg'),require('../assets/work/strongmen10.jpg')],
            hover: false,
            showMore: false
        }    
    ]
};
export default work;